@import 'variables';

@keyframes loading-bar {
  0% {
    width: 0;
    left: 0%;
  }
  23% {
    width: 30%;
    left: 0%;
  }
  61.9% {
    width: 30%;
    left: 70%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

$loader-height: 0.15em;
$loader-width: 50%;

$primary-color: $dark-blue;
$danger-color: $red;
$light-color: $gray-100;

button {
  &.btn-loading {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      position: absolute;
      bottom: 0;
      content: '';
      display: inline-block;
      height: $loader-height;
    }

    &::before {
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &::after {
      left: ($loader-width * -1);
      width: $loader-width;
      animation: loading-bar 1s infinite linear;
    }

    &.btn-primary {
      &::before {
        background-color: $primary-color;
      }
      &::after {
        background-color: darken($white, 10%);
      }
    }

    &.btn-danger {
      &::before {
        background-color: $danger-color;
      }
      &::after {
        background-color: darken($white, 10%);
      }
    }

    &.btn-light {
      &::before {
        background-color: $light-color;
      }
      &::after {
        background-color: darken($white, 10%);
      }
    }
  }
}

.modal-header button.close {
  outline: none;
}
