@import 'variables';
@import 'functions';

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: $gray-200;
  font-family: Lato, Arial, sans-serif;
  overflow-x: hidden;
}

.header {
  background-color: $white;
  position: relative;
  z-index: 2;
  padding-bottom: 0.4rem;

  .header-container {
    max-width: 1600px;
    margin: 0 auto;
  }

  .header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      margin: rem(16) rem(16) 0 0;
    }
  }

  h2 {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: rem(10) rem(14) rem(6);
    color: $gray-600;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.viewport {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$banner-height});
  overflow-y: auto;

  .content {
    flex: 1;
  }
}

.content {
  padding: rem(12);
  padding-bottom: $card-margin;
  max-width: 1600px;
}
